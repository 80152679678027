import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const KontaktR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="591.9px"
        height="1321.8px"
        viewBox="0 0 591.9 1321.8"
      >
        <GrainPattern id={id} x="90%" />
        <path
          fill={`url(#${id})`}
          d="M591.9,0c-10.8,6.5-20.9,14.1-29.7,23.7c-28.8,31.2-49,69.3-82,96.5c-31,25.6-67.7,44.1-104.3,62
	c-65.2,31.9-134.3,62.8-175.2,123.6c-40.1,59.7-40.3,141.4-62.2,211.4c-12,38.5-31.9,68.9-65,89.5c-29.5,18.3-60.8,34.7-70,72
	C-14,749.6,38.1,837.5,89.6,901.8c32,39.8,67.8,76.6,103.4,113.3c37.2,38.5,75.2,76.5,116.8,109.9c40.7,32.7,84.6,59,128.5,84.9
	c45.8,27.1,97,54,136.8,92.1c6.6,6.4,12.1,13,16.8,19.9V0z"
        />
      </svg>
    )
  }
)

export default KontaktR1
