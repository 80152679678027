import React, {
  PureComponent,
  FormEvent,
  FormEventHandler,
  InputHTMLAttributes,
  createRef,
} from "react"
import styles from "../templates/kontakt.module.css"
import "isomorphic-fetch"

const encode = (data: { [key: string]: string }) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const initialState = {
  email: "",
  name: "",
  message: "",
  isSubmittedError: false,
  isSubmittedSuccess: false,
}

export class ContactForm extends PureComponent {
  state = initialState

  formRef = createRef<HTMLFormElement>()

  handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    const form = e.currentTarget

    this.setState({
      ...initialState,
      isSubmittedError: false,
      isSubmittedSuccess: false,
    })

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          email: this.state.email,
          name: this.state.name,
          message: this.state.message,
        }),
      })
    } catch (error) {
      this.setState({ isSubmittedError: true, isSubmittedSuccess: false })
    }

    this.setState({ isSubmittedError: false, isSubmittedSuccess: true }, () =>
      form.reset()
    )
  }

  handleChange: FormEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value })
  }

  componentDidMount() {
    // fixes textarea invalid state on inital render
    if (this.formRef.current) this.formRef.current.reset()
  }

  render() {
    const {
      email,
      name,
      message,
      isSubmittedSuccess,
      isSubmittedError,
    } = this.state

    return (
      // @ts-ignore - netlify attrs
      <form
        ref={this.formRef}
        name="contact"
        netlify="true"
        netlify-honeypot="bot-field"
        action="/"
        onSubmit={this.handleSubmit}
        className={styles.form}
      >
        <p className={styles.honeypot}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        {isSubmittedSuccess ? <p>Tack för ditt meddelande!</p> : null}
        {isSubmittedError ? (
          <p>Något gick fel. Försök igen eller hör av dig via epost.</p>
        ) : null}
        <input
          type="text"
          name="name"
          placeholder="Namn"
          value={name}
          onChange={this.handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="E-post"
          value={email}
          onChange={this.handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Meddelande"
          value={message}
          onChange={this.handleChange}
          required
          cols={30}
          rows={10}
        />
        <button type="submit" className={styles.submit}>
          Skicka
        </button>
      </form>
    )
  }
}
