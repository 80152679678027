import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const KontaktT1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="1335.9px"
        height="540.5px"
        viewBox="0 0 1335.9 540.5"
      >
        <GrainPattern id={id} x="30%" y="-50%" />
        <path
          fill={`url(#${id})`}
          d="M0,0c63,54.8,130.1,105.5,186.4,166.9c32.9,35.9,61,76.3,99.6,107.4c35.3,28.5,75.6,45.6,115.2,63.2
	c45.5,20.3,85.2,43.9,124.1,77.7c35.4,30.8,68.8,64.1,108.3,89.6c59.5,38.4,123.4,46.5,172.1,21.3c26.6-13.7,46.5-35.3,62.9-59
	c38.6-43,105.8-49.6,140.7-96.6c23.3-31.3,37.9-68.4,65.5-96.4c25.9-26.4,58.1-46.5,90.1-66.2c57.1-35.1,118-69.5,149.2-129.3
	c12.5-23.9,18.4-50.7,21.9-78.5H0z"
        />
      </svg>
    )
  }
)

export default KontaktT1
