import React, { FunctionComponent } from "react"
import styles from "./kontakt.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import { oc } from "ts-optchain"
import Image, { FluidObject } from "gatsby-image"
import cx from "classnames"
import KontaktR1 from "../components/blobs/kontakt-r-1"
import KontaktT1 from "../components/blobs/kontakt-t-1"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import { KontaktQuery } from "../generated/graphql"
import renderAst from "../lib/render-ast"
import { ContactForm } from "../components/contact-form"

const KontaktPage: FunctionComponent<{ data: KontaktQuery }> = ({
  data: { page },
}) => {
  const refBlobT = useParallax<SVGSVGElement>(null, makeTransformY(-0.6))
  const refBlobR = useParallax<SVGSVGElement>(null, makeTransformY(0.6))

  if (!page) {
    throw new Error("missing page data")
  }

  return (
    <Layout toggleColor="purple">
      <SEO title={page.title || ""} />
      <KontaktT1 className={styles.blobT} ref={refBlobT} />
      <Hero backgroundColor="yellow-dark">
        <h1>{page.heroTitle}</h1>
        {renderAst(oc(page).heroText.childMarkdownRemark.htmlAst({}))}
      </Hero>
      <div className={cx("container", styles.personGrid)}>
        {oc(page)
          .contactPersons([])
          .map((node, i) => {
            if (!node) {
              return null
            }

            const { email, title, name, telephone, image } = node

            return (
              <div key={i}>
                <Image
                  className={styles.personImage}
                  loading="eager"
                  fluid={
                    oc(image).localFile.childImageSharp.fluid({}) as FluidObject
                  }
                  alt=""
                />
                <h3 className={styles.name}>{name}</h3>
                <p className={styles.jobTitle}>{title}</p>
                <address className={styles.personAddress}>
                  <a href={`mailto:${email}`}>{email}</a> <br />
                  <span>{telephone}</span>
                </address>
              </div>
            )
          })}
      </div>
      <div className={cx("container", "grid", styles.formGrid)}>
        <div className={styles.contactText}>
          <h2>Kontakta oss</h2>
          <address className={styles.address}>
            <div className={styles.contact}>
              {renderAst(
                oc(page).contactFormIntro.childMarkdownRemark.htmlAst()
              )}
            </div>
          </address>
        </div>
        <ContactForm />
      </div>
      <KontaktR1 className={styles.blobR} ref={refBlobR} />
    </Layout>
  )
}
export const query = graphql`
  query Kontakt($id: String!) {
    page: contentfulKontakt(id: { eq: $id }) {
      title
      heroTitle
      contactFormIntro {
        childMarkdownRemark {
          htmlAst
        }
      }
      heroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      contactPersons {
        name
        title
        telephone
        email
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 304, maxHeight: 304) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default KontaktPage
