import React, { FunctionComponent } from "react"
import cx from "classnames"
import styles from "./hero.module.css"
interface HeroProps {
  backgroundColor?: "pink" | "purple" | "purple-dark" | "red" | "yellow-dark"
}

const Hero: FunctionComponent<HeroProps> = ({ backgroundColor, children }) => {
  return (
    <section
      className={cx(styles.hero, {
        [styles.pink]: backgroundColor === "pink",
        [styles.purple]: backgroundColor === "purple",
        [styles.purpleDark]: backgroundColor === "purple-dark",
        [styles.yellowDark]: backgroundColor === "yellow-dark",
        [styles.red]: backgroundColor === "red",
      })}
    >
      <div className="grid">
        <div className={styles.content}>{children}</div>
      </div>
    </section>
  )
}
export default Hero
